import { forwardRef } from 'react';
import type { ReactElement } from 'react';
import classNames from 'classnames';
import { Note } from '../../../atoms/note';
import type { SelectProps } from './types';
import styles from './select.module.scss';

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
    ({ children, className, errorMessage, options, style, defaultValue, ...props }, ref): ReactElement => (
        <label className={classNames(styles.label, className)} style={style}>
            <select
                {...props}
                className={classNames(styles.select, { [styles.error]: errorMessage })}
                defaultValue={defaultValue || ""}
                ref={ref}
            >
                <option disabled hidden value="">Wybierz opcję</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {errorMessage ? <Note fill typ='warning'>
                    <p className={classNames(styles.message, { [styles.error]: errorMessage })}>
                        {errorMessage}
                    </p>
                </Note> : null}
            {children}
        </label>
    )
);

Select.displayName = 'Select';
