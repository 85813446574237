/* eslint-disable react/jsx-no-leaked-render, react/jsx-sort-props -- I don't hafe time to fix */
import {forwardRef} from 'react';
import type {ReactElement} from 'react';
import classNames from 'classnames';
import {Note} from '../../../atoms/note';
import type {InputProps} from './types';
import styles from './input.module.scss';

export const Input = forwardRef<HTMLInputElement, InputProps>(
    ({children, className, disabled, errorMessage, style, ...props}, ref): ReactElement => (
        <label className={classNames(styles.label, className, {[styles.disabled]: disabled})} style={style}>
            <input
                {...props}
                readOnly={disabled}
                className={classNames(styles.input, {[styles.error]: errorMessage})}
                ref={ref}
            />
            {errorMessage && (
                <Note typ='warning' fill>
                    <p className={classNames(styles.message, {[styles.error]: errorMessage})}>
                        {errorMessage}
                    </p>
                </Note>
            )}
            {children}
        </label>
    )
);

Input.displayName = 'Input';
